.chosen {

  .main {
    &__image {
      height: calculateRem(244px);
    }

    &__title {
      font-size: calculateRem(16px);
    }

    &__text {
      ul {
        font-size: calculateRem(14px);
      }
    }
  }
}