// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after, $text-align: center) {
  white-space: nowrap;

  @if $text-align != false {
    text-align: $text-align;
  }

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  > * {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
  }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto) {
  display: table;

  .#{$child-name1} {
    display: table-cell;
    vertical-align: $valign;
    width: $width1;
  }

  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign;
    width: $width2;
  }
}

// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}
@mixin sizeRem($width, $height: $width, $scale:1) {
  $width: $width * $scale / 10px;
  $height: $height * $scale / 10px;
  width: #{$width}rem;
  height: #{$height}rem;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value:on) {
  @if $value==on {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } @else {
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: auto;
  }
}

/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 121%;
  white-space: nowrap;
}

@mixin fillStroke($color:$black, $stroke_width:1.5px) {
  stroke: $color !important;
  stroke-width: $stroke_width;
  stroke-linecap: round;
  stroke-linejoin: round;
}


/// Animate css properties
// usage
// scss - @include animate(color);
//     or @include animate(color width);
//     or @include animate(color width, 1s, linear);
//
$animation-speed: .3s !default;

@mixin animate($properties, $duration: $animation-speed, $easing: ease-in-out) {
  $list: ();

  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }

  transition: $list;
}

@mixin center() {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
}

@function calculateRem($size) {
  $remSize: $size / 10px;
  @return #{$remSize}rem;
}

/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include fontSize(16px);
/// }

@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@mixin fullscreen($top: 0, $left: 0, $right: 0, $bottom: 0) {
  position: absolute;
  top: $top;
  left: $left;
  right: $right;
  bottom: $bottom;
}

@mixin column($count: 2, $gap: 40px) {
  column-count: $count;
  column-gap: $gap;
}

@mixin bg-size($size: cover, $pos: 50%) {
  background-size: $size;
  background-position: $pos;
  background-repeat: no-repeat;
}

/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content
  }

  &::-moz-placeholder {
    opacity: 1;
    @content
  }

  &:-moz-placeholder {
    @content
  }

  &:-ms-input-placeholder {
    @content
  }

  &.placeholder {
    @content
  }
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  -ms-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($top, $left, $blur, $color, $inset: false) {
  @if $inset {
    -webkit-box-shadow: inset $top $left $blur $color;
    -moz-box-shadow: inset $top $left $blur $color;
    box-shadow: inset $top $left $blur $color;
  } @else {
    -webkit-box-shadow: $top $left $blur $color;
    -moz-box-shadow: $top $left $blur $color;
    box-shadow: $top $left $blur $color;
  }
}

@mixin scale($property, $values) {
  $list: ();

  @each $prop in $values {
    $str: round($prop * $scale_to);
    $list: join($list, #{$str});
  }

  #{$property}: #{$list};
}

