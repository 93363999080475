.footer {
  margin-top: calculateRem(45px);

  &__top {
    background-color: $white;
    padding: calculateRem(24px) 0;

    .container-fluid {
      @extend %displayFlex;
    }
  }

  &__logo {
    width: calculateRem(200px);
    display: block;
  }

  &__bottom {
    background-color: $bold-black;
    padding: calculateRem(12px) 0;
    color: $white;
  }

  @include media("<=tablet") {
    &__top {
      padding: 20px 0;

      .container-fluid {
        display: block;

        > * {
          margin-bottom: 16px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}