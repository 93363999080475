.rubrics {
  //background-image: url("https://media.istockphoto.com/id/508552962/photo/american-football-stadium.jpg?s=612x612&w=0&k=20&c=krKG8iktC9ly9Ym8aUETI6yrZCoq63mZ5EB64hKPiOI=");
  //background-size: cover;
  background-color: $white;
  padding: calculateRem(20px);
  border-radius: 12px;

  .news {
    &__image {
      //padding: calculateRem(10px);
      border-radius: 8px;

      img {
        width: 100%;
        object-fit: cover;
      }
    }
  }
}