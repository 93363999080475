.advertisement {
  height: 100%;
  max-height: calculateRem(508px);
  display: flex;
  flex-direction: column;
  row-gap: calculateRem(20px);

  &__item {
    flex: 1;
    display: block;
    border-radius: 8px;
    overflow: hidden;
    background-color: #ffd525;

    img {
      @include size(100%);
      object-fit: contain;
    }
  }
}