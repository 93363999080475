.calendar {
  &__nav {
    @extend %displayFlex;
    margin-bottom: calculateRem(20px);
    font-size: calculateRem(20px);
    font-weight: bold;
    padding: 0 calculateRem(10px);
  }

  &__table {
    text-align: center;

    td {
      a {
        display: block;
        padding: calculateRem(5px) calculateRem(10px);
        color: $black;

        &:hover {
          background-color: #eee;
        }
      }

      &.active {
        a {
          background-color: #f13e3f;
          color: $white;
        }
      }
    }
  }
}