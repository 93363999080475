// Typography
body {
  font-family: $base-font;
  font-weight: normal;
  color: $base-text-color;
  font-size: calculateRem(16px);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
.h {
  display: block;
  font-family: $base-font;
  font-weight: 600;
  margin: 0;
  color: $black;
}

.section-title {
  font-size: calculateRem(32px);
  line-height: calculateRem(40px);
  margin-bottom: calculateRem(15px);
  font-weight: bold;

  @include media("<=tablet") {
    font-size: 26px;
  }
}

strong {
  font-weight: 600;
}

a {
  color: $blue;
  font-weight: normal;
  transition: $animation-speed ease;
  //transition-property: color, border-color;
  text-decoration: none;
  //border-bottom: 1px solid;
  border-color: $blue;

  //&:hover,
  //&:focus {
  //  color: currentColor;
  //}
}

.btn {
  display: inline-flex;
  background: $light-green;
  color: $green;
  @extend %transition;
  border: 1px solid transparent;
  font-weight: normal;
  @include fontSize(18px);
  padding: calculateRem(20px);
  align-items: center;
  justify-content: center;
  line-height: calculateRem(24px);
  width: max-content;
  cursor: pointer;

  &:hover, &:focus {
    background: $white;
    border-color: currentColor;
  }

  &--with_icon {
    svg {
      margin-right: calculateRem(9px);
    }
  }

  &--small {
    @include fontSize(14px);
    padding: calculateRem(10px) calculateRem(16px);
  }

  @include media("<=tablet") {
  }
}

.link {
  display: flex;
  align-items: center;
  color: $blue;
  font-weight: 500;
  font-size: calculateRem(16px);

  svg {
    margin-left: calculateRem(5px);
  }

  &:hover {
    color: $black;
  }

  @include media("<=tablet") {
    font-size: 14px;
    svg {
      margin-left: 13px;
    }
  }

}

@each $name, $color in $colors {
  .btn-#{$name} {
    color: $white;
    background: $color;

    &:hover, &:focus {
      background: $white;
      color: $color;
      border-color: currentColor;
    }
  }

  .btn-#{$name}-transparent {
    color: $color;
    background: transparent;
    border-color: $color;

    &:hover, &:focus {
      border-color: $color;
      background: $color;
      color: $white;
    }
  }

}

.text-center {
  text-align: center !important;
  justify-content: center;
}

