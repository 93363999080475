$breakpoints: ("xs-phone": 350px,
        "phone": 420px,
        "tablet": 767px,
        "medium" : 991px,
        "wrapper": 1024px,
        "desktop": 1200px,
        "widescreen": 1299px,
        "laptop": 1399px,
        "Xwidescreen": 1680px);
// ligatured operators ≥ ≤
@import "bootstrap/bootstrap";
@import "vendors/include-media";
@import 'vendors/normalize';
@import "base/variables";
@import "vendors/burger";
//@import "vendors/fancybox";
@import "vendors/modal-video";
@import "base/mixins";
//@import "base/fonts";
@import "base/functions";
@import "base/reset";
@import "base/typography";
@import "base/helpers";
@import "parts/header";
@import "parts/footer";
@import "parts/bread-crumbs";
@import "parts/section/not-found";
@import "parts/section/main";
@import "parts/section/stories";
@import "parts/section/chosen";
@import "parts/section/advertisement";
@import "parts/section/news";
@import "parts/section/rubrics";
@import "parts/section/schedule";
@import "parts/section/calendar";

@import "base/forms";
@import "vendors/flickity";
body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  overflow-x: hidden;
  background-color: #F6F7FE;

  &.fixed {
    @include fullscreen();
    position: fixed;
    overflow-y: scroll;
  }
}

#all_parts {
  flex: 1;

  .container-fluid {
    > * {
      margin-bottom: calculateRem(30px);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

html {
  font-size: $font-size-base;

  @include media("<=medium") {
    font-size: 50%;
  }

  @include media("<=tablet") {
    font-size: $font-size-base;
  }
}

p {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
}

ul {
  @extend %listreset
}

hr {
  margin: 0;
  border-color: #ECEDF0;
}

.container-fluid {
  max-width: 1060px;
  padding: 0 30px;

  @include media("<=tablet") {
    padding: 0 16px;
  }
}

.row {
  row-gap: calculateRem(20px);
}