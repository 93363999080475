// forms
input, select {
  -webkit-appearance: none;
}

.form-notification {
  @include fontSize(14px);
  margin-bottom: calculateRem(15px);

  &--error {
    color: #dc3545;
  }

  &--success {
    color: $green;
  }
}

.form-group {
  margin-bottom: calculateRem(28px);

  label {
    color: $special-gray;
    @include fontSize(16px);
    margin-bottom: calculateRem(8px);
  }

  .error-text {
    color: #dc3545 !important;
    display: block;
    margin-top: calculateRem(5px);
    font-size: calculateRem(13px);
  }

  .form-control {
    border-color: $borderColor;
    background: $white;
    font-weight: 500;
    color: $black;
    font-size: calculateRem(16px);
    padding: calculateRem(15px) calculateRem(20px);
    -webkit-appearance: none;

    &.is-invalid {
      border-color: #dc3545 !important;
    }

    &::placeholder {
      color: rgba($black, .3);
    }

    &:focus {
      outline: none;
      border-color: $border-color;
      box-shadow: unset;
    }
  }

  input[type="number"] {
    &::-webkit-outer-spin-button, &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  select.form-control {
    background: url("../images/svg-icons/arrow-bottom.svg") right 20px top 50% no-repeat;
    background-size: 12.5px 6.75px;
    padding-right: calculateRem(40px);
  }

  textarea {
    resize: unset;
  }

  &--search {
    position: relative;
    margin-bottom: 0;

    button {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: transparent;
      border: none;
    }

    .form-control {
      padding: calculateRem(10px);
      padding-left: calculateRem(35px);
      padding-bottom: calculateRem(8px);
      font-size: calculateRem(14px);
      border: none;
      border-bottom: 1px solid rgba(#2f2f2f, .3);
      border-radius: 0;
      background-color: transparent;

      &:focus {
        border-color: rgba(#2f2f2f, .3);
      }
    }
  }

  @include media("<=tablet") {

  }
}

.custom-radio {
  background: $white;
  border: 2px solid $border-color;
  border-radius: 6px;
  padding: calculateRem(25px) calculateRem(30px);
  margin-bottom: calculateRem(24px);
  @extend %transition;

  &__label {
    @include fontSize(17px);
    font-weight: 600;
    color: $black;
    @extend %displayFlex;
    justify-content: unset;
    position: relative;
    cursor: pointer;

    svg {
      margin-right: calculateRem(12px);
    }

    &:before {
      content: '';
      position: absolute;
      right: 6px;
      top: 8px;
      @include size(12px);
      border-radius: 50%;
      background-color: transparent;
      @extend %transition;
    }

    &:after {
      content: '';
      position: absolute;
      right: 0;
      top: 2px;
      @include size(24px);
      border-radius: 50%;
      border: 2px solid $border-color;
      @extend %transition;
    }
  }

  .form-group {
    margin-bottom: 0;
    padding-top: calculateRem(25px);
    margin-top: calculateRem(25px);
    border-top: 1px solid #E5E7EF;
  }
}


//content: "\f046";
