.header {
  font-weight: 600;
  margin-bottom: calculateRem(30px);
  z-index: 10;

  &__top {
    @extend %displayFlex;
    position: relative;
    height: calculateRem(80px);
  }

  &__tools {
    display: flex;
    align-items: center;
    flex: 1;

    li {
      margin-right: calculateRem(32px);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__weather {
    display: flex;
    align-items: center;

    img {
      margin-right: calculateRem(8px);
    }
  }

  &__currency {
    margin-right: auto;
    flex: 1;
    pointer-events: none;

    &__slider {
      display: flex;

      &.flickity-enabled {
        display: block;
      }
    }

    &__item {
      pointer-events: auto;
      width: max-content;
      display: flex;
      align-items: center;
      white-space: nowrap;
      opacity: 0;

      span {
        @include size(calculateRem(24px));
        @extend %displayFlex;
        justify-content: center;
        margin-right: calculateRem(8px);

        &:before {
          content: '';
          @include size(calculateRem(6px));
          border-radius: 50%;
          background-color: $black;
        }
      }

      &:first-child {
        opacity: 1;
      }

      &.up {
        span {
          &:before {
            display: none;
          }
        }

        svg {
          color: $green;
        }
      }

      &.down {
        span {
          &:before {
            display: none;
          }
        }

        svg {
          transform: rotate(180deg);
          color: $red;
        }
      }
    }
  }

  &__logo {
    @include center();
    width: calculateRem(228px);
    z-index: 1;

    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  &__search {
    width: calculateRem(220px);
  }

  &__nav {
    background-color: $bold-black;
    padding: calculateRem(18px) 0;
    color: $white;
    @extend %transition;

    a {
      color: inherit;
      font-size: calculateRem(14px);
    }

    .container-fluid {
      @extend %displayFlex;
    }
  }

  &__menu {
    display: flex;
    align-items: center;
    flex: 1;

    li {
      margin-right: calculateRem(24px);
    }

    a {

      &.active, &:hover {
        color: $red;
      }
    }
  }

  &__socials {
    display: flex;
    align-items: center;

    li {
      margin-left: calculateRem(16px);
    }

    a {
      @include size(calculateRem(32px));
      @extend %displayFlex;
      justify-content: center;
      border-radius: 50%;
      color: $white;

      &.fb {
        background-color: #1877F2;
      }

      &.insta {
        background-color: #8A3AB9;
      }
    }
  }

  &__burger {
    @include size(24px);
    @extend %displayFlex;
    justify-content: center;
    cursor: pointer;

    i {
      @include burger(23px, 2px, 4px, $black)
    }
  }

  @include media("<=tablet") {
    margin-bottom: 10px;

    &__top {
      height: auto;
      padding: 20px 0;
    }

    &__logo {
      position: unset;
      transform: unset;
      width: 150px;
    }

    &__tools {
      display: none;
    }

    &__nav {
      position: fixed;
      top: 67px;
      bottom: 0;
      right: -100%;
      overflow-y: auto;
      width: 100%;

      .container-fluid {
        flex-direction: column;
        text-align: center;
      }
    }

    &__search {
      margin-bottom: 30px;

      .form-group {
        button {
          color: $white;
        }

        .form-control {
          color: $white;
          border-color: $white;

          &::placeholder {
            color: rgba($white, .5);
          }

          &:focus {
            border-color: $white;
          }
        }
      }
    }

    &__menu {
      display: block;

      a {
        font-size: 16px;
      }

      li {
        margin-bottom: 20px;
        margin-right: 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &__socials {
      margin-top: 30px;

      li {
        &:first-child {
          margin-left: 0;
        }
      }
    }

    #burger_toggle:checked ~ {
      .container-fluid {
        .header__burger i {
          @include burger-to-cross();
        }
      }

      .header__nav {
        right: 0;
      }
    }
  }
}