// Colors
// Color calculator - http://razorjam.github.io/sasscolourfunctioncalculator/
// Color names - http://name-of-color.com/

$white: #fff;
$black: #2E2E2E;
$bold-black: #191919;
$blue: #151E50;
$gray: #A5A9B0;
$special-gray: #696F78;

$green: #02AD89;
$light-green: #F2FAF9;

//$orange: #F6861F;
//$yellow: #FFD436;
$red: #d04848;
//$blue-500: #3192EC;
//$blue-100: #F7F7F9;

$borderColor: #DBDEE4;

$brown: #1743DF;

$colors: "white" $white,
"black" $black,
"blue" $blue,
"gray" $gray,
"special-gray" $special-gray,
"green" $green,
"light-green" $light-green;

@each $name, $color in $colors {
  .text-#{$name} {
    color: $color !important;
  }
  .bg-#{$name} {
    background-color: $color !important;
  }
}

$base-font: 'SF Pro Display', sans-serif;

// Body
$base-text-color: $black !default;
$base-background-color: $white !default;
$font-size-base: 62.5%;
$line-height-base: normal !default;

$base-font-family: $base-font !default;
$base-min-width: 320px;
//$base-max-width: 1680px;
$base-max-width: 12000px;

$animation-speed: 0.3s;

$border-radius: 6px;
$border-radius-second: 12px;
$scale_to: .75;
