.breadcrumbs-block {
  padding: calculateRem(24px) 0 0;
  margin-bottom: calculateRem(52px);

  &--white {
    padding: calculateRem(24px) 0;
    margin-bottom: 0;
    background-color: $white;
  }

  @include media("<=tablet") {
    margin-bottom: 24px;
    &--white {
      margin-bottom: 0;
      padding-top: 16px;
    }
  }
}

.breadcrumbs {
  display: flex;
  align-items: center;
  @extend %hide-scroll;

  li {
    @include fontSize(13px);
    font-weight: 400;
    color: $black;

    a {
      color: $gray;
      display: flex;
      align-items: center;

      &:after {
        content: '';
        display: inline-block;
        margin: 0 calculateRem(12px);
        width: 4px;
        height: 6px;
        background-image: url("../images/svg-icons/arrow-right.svg");
        background-repeat: no-repeat;
      }
    }
  }

  @include media("<=tablet") {
    width: 100%;
    overflow-x: auto;

    li {
      width: max-content;
      font-size: 11px;
      white-space: nowrap;

      a {
        white-space: nowrap;

        &:after {
          margin: 2px 12px 0;
          width: 5px;
          height: 8px;
        }
      }
    }
  }
}