.main {
  $this: &;

  &__item {
    position: relative;
    display: block;
    border-radius: 8px;
    box-shadow: 6px 21px 35px 0 rgba(54, 62, 147, 0.05);
    overflow: hidden;

    &:hover {
      #{$this}__image img {
        transform: scale(1.1);
      }
    }

    &:before {
      content: '';
      @include fullscreen();
      top: 45%;
      background: linear-gradient(180deg, rgba(30, 30, 30, 0.00) 0%, rgba(30, 30, 30, 0.75) 50%, rgba(30, 30, 30, 0.72) 100%);
      opacity: .8;
      z-index: 2;
    }
  }

  &__image {
    @include size(100%);
    position: relative;
    height: calculateRem(160px);
    background-color: #f9f1e7;
    display: flex;
    justify-content: center;

    &__blur {
      @include fullscreen();
      background-size: cover;
      background-position: center;
      filter: blur(6px);
    }

    img {
      height: 100%;
      max-width: unset;
      object-fit: contain;
      @extend %transition;
      position: relative;
      z-index: 1;
    }
  }

  &__text {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    padding: calculateRem(16px);
    color: $white;

    > * {
      z-index: 2;
      position: relative;
    }

    ul {
      font-size: calculateRem(10px);
      display: flex;
      align-items: center;

      li {
        padding-right: calculateRem(8px);
        margin-right: calculateRem(8px);
        position: relative;

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:after {
          content: '';
          @include size(4px);
          border-radius: 50%;
          position: absolute;
          right: -2px;
          top: 50%;
          transform: translateY(-50%);
          background-color: $white;
        }
      }
    }
  }

  &__title {
    font-size: calculateRem(12px);
    line-height: calculateRem(20px);
    color: $white;
    margin-bottom: calculateRem(5px);
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .col-12 {
    #{$this}__image {
      height: calculateRem(325px);
    }

    #{$this}__title {
      font-size: calculateRem(20px);
      margin-bottom: calculateRem(15px);
      line-height: calculateRem(28px);
    }

    #{$this}__text {
      ul {
        font-size: calculateRem(14px);
      }
    }
  }
}