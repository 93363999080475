%border-collapsed {
  box-shadow: 1px 0 0 0 #E3E5EB,
  0 1px 0 0 #E3E5EB,
  1px 1px 0 0 #E3E5EB, /* Just to fix the corner */
  1px 0 0 0 #E3E5EB inset,
  0 1px 0 0 #E3E5EB inset;
}

%hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-track {
    box-shadow: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: none;
  }
}

%scroll {
  &::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2.5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: none;
    border-radius: 2.5px;
  }
}

%switcher {
  .switch {
    position: relative;
    display: inline-block;
    width: 56px;
    height: 32px;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $green;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 24px;
    width: 24px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    background-color: $orange;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(24px);
    -ms-transform: translateX(24px);
    transform: translateX(24px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

%displayFlex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

// list reset
%listreset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.listreset {
  @extend %listreset;
}

// clearfix
%clearfix {
  &:after {
    content: '';
    display: block;
    clear: both;
  }
}

.clearfix {
  @extend %clearfix;
}

// transition
%transition {
  transition: all 0.3s ease-in-out;
}

/// * Helper class to truncate and add ellipsis to a string too long for it to fit
/// * on a single line.
/// * 1. Prevent content from wrapping, forcing it on a single line.
/// * 2. Add ellipsis at the end of the line.

.ellipsis {
  white-space: nowrap; /* 1 */
  text-overflow: ellipsis; /* 2 */
  overflow: hidden;
}

.ellipsis-gradient {
  &:after {
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    content: "";
    background: linear-gradient(to top,
            rgba(255, 255, 255, 1) 20%,
            rgba(255, 255, 255, 0) 80%
    );
    pointer-events: none; /* so the text is still selectable */
  }
}

%arrow-bottom {
  border-left: calculateRem(4px) solid transparent;
  border-right: calculateRem(4px) solid transparent;
  border-top: calculateRem(4px) solid currentColor;
}


%custom-checkboxes {
  input[type="checkbox"] {
    display: none;

    &:checked + label {
      &:after {
        opacity: 1;
      }
    }
  }

  label {
    margin-bottom: calculateRem(8px);
    color: #5B5F66;
    @include fontSize(14px);
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;

    &:last-child {
      padding-bottom: calculateRem(17px);
    }

    &:before {
      content: '';
      border-radius: 2px;
      border: 1px solid #DADBDD;
      display: inline-block;
      @include size(16px);
      min-width: 16px;
      margin-right: calculateRem(12px);
    }

    &:after {
      content: '';
      display: block;
      border: 1.5px solid $orange;
      border-top: unset;
      border-right: unset;
      transform: rotate(-45deg);
      @include size(6.5px, 4px);
      position: absolute;
      left: 4px;
      top: 7px;
      @extend %transition;
      opacity: 0;
    }
  }
}