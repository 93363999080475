.news {

  $this: &;

  &__tags {
    display: flex;
    align-items: center;
    margin-bottom: calculateRem(15px);
    overflow: hidden;

    &.flickity-enabled {
      display: block;

      #{$this}__item {
        margin-right: calculateRem(10px);
      }
    }

    &__item {
      font-size: calculateRem(14px);
      font-weight: 600;
      display: block;
      padding: calculateRem(9px) calculateRem(20px);
      border: 1px solid $bold-black;
      color: $bold-black;
      border-radius: 8px;
      transition: background-color .3s linear, color .3s linear;
      white-space: nowrap;
      margin-right: calculateRem(15px);

      &.active, &:hover {
        background-color: $bold-black;
        color: $white;
      }
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    height: calc(100%);

    &:hover {
      #{$this}__image img {
        transform: scale(1.1);
      }
    }
  }

  &__image {
    overflow: hidden;
    height: calculateRem(192px);
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: #f9f1e7;
    position: relative;

    img {
      position: relative;
      z-index: 1;
      height: 100%;
      max-width: unset;
      object-fit: contain;
      @extend %transition;
    }
  }

  &__text {
    background-color: $white;
    padding: calculateRem(12px);
    flex: 1;
  }

  &__category {
    font-size: calculateRem(12px);
    font-weight: 500;
    line-height: calculateRem(20px);
    color: #B499FF;
    padding: calculateRem(6px) calculateRem(12px);
    background-color: #F3EFFF;
    border-radius: 4px;
  }

  &__date {
    color: #A2A6BB;
    font-size: calculateRem(14px);
  }

  &__title {
    font-size: calculateRem(18px);
    line-height: calculateRem(26px);
  }

  @include media("<=tablet") {
    &__tags {
      margin-left: -16px;
      margin-right: -16px;
      padding: 0 16px;

      .flickity-viewport {
        overflow: visible;
      }

      &__item {
        font-size: 14px;
        padding: 8px 15px;
        margin-right: 12px;
      }
    }
  }
}