.stories {

  $this: &;

  &__slider {
    flex-wrap: nowrap;
    overflow: hidden;

    .flickity-slider {
      > * {
        padding: 0 calculateRem(10px);
      }
    }

    .flickity-button {
      @include size(calculateRem(25px));
      color: $white;
      background-color: $black;

      &.previous {
        left: calculateRem(20px);
      }

      &.next {
        right: calculateRem(20px);
      }

      svg {
        @include size(50%);
        @include center();
      }
    }
  }

  &__item {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    display: block;

    &:hover {
      #{$this}__text {
        opacity: 1;
      }
    }
  }

  &__text {
    @include fullscreen();
    background: rgba(46, 46, 46, 0.88);
    backdrop-filter: blur(4px);
    @extend %transition;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: calculateRem(10px);
    opacity: 0;
  }

  &__image {
    position: relative;
    padding-bottom: 100%;

    img {
      @include size(100%);
      @include fullscreen();
      object-fit: cover;
    }
  }

  &__logo {
    width: calculateRem(77px);
    margin: 0 auto;

    img {
      width: 100%;
    }

    //filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7499%) hue-rotate(77deg) brightness(112%) contrast(101%);
  }

  &__title {
    font-size: calculateRem(12px);
    color: $white;
    font-weight: normal;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
}