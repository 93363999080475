.schedule {
  table {
    width: 100%;
  }

  &__item {
    background-color: $white;
    padding: calculateRem(20px);
    border-radius: 8px;
  }

  &__table {
    display: flex;
    overflow: hidden;

    &.flickity-enabled {
      display: block;
    }

    &__item {
      min-width: 100%;
      width: 100%;
    }

    thead {
      th, td {
        background-color: $bold-black;
        color: $white;
        padding: calculateRem(10px);
      }
    }

    tbody {
      td {
        padding: calculateRem(5px) calculateRem(10px);
      }
    }
  }

  &__title {
    margin-bottom: calculateRem(15px);
    font-weight: bold;
  }

  @include media("<=tablet") {
    &__item {
      padding: 15px 10px;
    }

    &__table {
      font-size: 14px;

      thead {
        th, td {
          padding: 5px 10px;
        }
      }

      tbody {
        td {
          padding: 5px 10px;
        }
      }
    }
  }
}