.not-found {
  padding: calculateRem(60px) 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  min-height: 35vh;

  h1 {
    font-size: calculateRem(40px);
    color: $red;
    font-weight: 600;
    b{
      font-size: calculateRem(120px);
    }
  }

  .btn{
    margin-top: calculateRem(30px);
  }
}