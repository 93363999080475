html {
  box-sizing: border-box;
  height: 100%;
  position: relative;
  //overflow: visible !important;
  //overflow-x: hidden !important;
  &.desktop body {
    //margin-right: -17px;
  }
}

*, *:before, *:after {
  box-sizing: inherit;
  outline: none;
}

* {
  max-height: 1000000px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// adaptive images
img {
  max-width: 100%;
  height: auto;
}

// google map fix
.gm-style img {
  max-width: none
}

svg * {
  fill: currentColor;
  //transition: all $animation-speed ease;
}

